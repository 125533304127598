











































































































































































































































import Vue from 'vue';
import 'vue-select/dist/vue-select.css';
import MASCHINENKENNZIFFERS from '@/config/maschinenkennziffer';
import { onCurrencyFocus, recursiveFillData } from '@/helper/functions';
import { exportToPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import ZuersZone from '@/components/form/zuers-zone.vue';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'individual-insurance-basic',
  mixins: [dataChangeMixin],
  components: {
    ZuersZone,
  },
  data() {
    return {
      name: 'windenergieanlagen-insurance-basic',
      key: 'WINDENERGIEANLAGEN_INSURANCE',
      versicherungsort: {
        Strabe: '',
        PLZ: '',
        Ort: '',
        Nr: '',
        ZursZone: '',
        Starkregenzone: '',
      },
      artDerWEA: null,
      MASCHINENKENNZIFFER: {
        name: null,
        value: null,
      },
      deckungsform: null,
      MKZ: null,
      anzahlDerAnlagen: 1,
      versicherungssummeJeAnlage: 0,
      versicherungssummeBUJeAnlage: 0,
      SBMaschine: 0,
      SBBU: 0,
      haftzeitBU: 0,
      nachlassFurVollwartungsvertrag: 0,
      MASCHINENKENNZIFFERS,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'insuranceFormsApiData'],

  watch: {
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        // console.log(this.insuranceData);
        this.fillApiValuesData();
      }
    },
    vuexZurs(newValue: string) {
      this.versicherungsort.ZursZone = newValue;
      this.versicherungsort.Starkregenzone = newValue;
    },
  },
  methods: {
    ...mapMutations('zuersZoneModule', {
      updatePlace: 'SET_PLACE',
      updatePostalCode: 'SET_POSTAL_CODE',
      updateStreet: 'SET_STREET',
      updateHouseNumber: 'SET_HOUSE_NUMBER',
      updateZurs: 'SET_ZURS',
      resetAddres: 'RESET_STATE',
    }),
    setZuersAddressToStore() {
      this.resetAddres();
      this.updatePlace(this.versicherungsort.Ort);
      this.updatePostalCode(this.versicherungsort.PLZ);
      this.updateStreet(this.versicherungsort.Strabe);
      this.updateHouseNumber(this.versicherungsort.Nr);
      this.updateZurs(this.versicherungsort.ZursZone);
    },
    onCurrencyFocus,
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.basicData) {
        this.anzahlDerAnlagen = Number(this.insuranceFormsApiData.basicData.anzahlDerAnlagen);
        this.versicherungssummeJeAnlage = Number(this.insuranceFormsApiData.basicData.versicherungssummeJeAnlage);
        this.versicherungssummeBUJeAnlage = Number(this.insuranceFormsApiData.basicData.versicherungssummeBUJeAnlage);
        this.nachlassFurVollwartungsvertrag = Number(this.insuranceFormsApiData.basicData.nachlassFurVollwartungsvertrag);
        this.MASCHINENKENNZIFFER = this.insuranceFormsApiData.basicData.MASCHINENKENNZIFFER;
        this.deckungsform = this.insuranceFormsApiData.basicData.deckungsform;
        recursiveFillData(this.versicherungsort, this.insuranceFormsApiData.basicData.versicherungsort);
      }
    },
    setValues(insuranceData: any) {
      this.versicherungsort = insuranceData.versicherungsort;
      this.artDerWEA = insuranceData.artDerWEA;
      this.deckungsform = insuranceData.deckungsform;
      this.MKZ = insuranceData.MKZ;
      this.anzahlDerAnlagen = insuranceData.anzahlDerAnlagen;
      this.versicherungssummeJeAnlage = insuranceData.versicherungssummeJeAnlage;
      this.versicherungssummeBUJeAnlage = insuranceData.versicherungssummeBUJeAnlage;
      this.SBMaschine = insuranceData.SBMaschine;
      this.SBBU = insuranceData.SBBU;
      this.haftzeitBU = insuranceData.haftzeitBU;
      this.nachlassFurVollwartungsvertrag = insuranceData.nachlassFurVollwartungsvertrag;
      this.MASCHINENKENNZIFFER = insuranceData.MASCHINENKENNZIFFER;
    },
    onSubmit(goNextPage = true) {
      const data = {
        versicherungsort: this.versicherungsort,
        artDerWEA: this.artDerWEA,
        deckungsform: this.deckungsform,
        MKZ: this.MKZ,
        anzahlDerAnlagen: this.anzahlDerAnlagen,
        versicherungssummeJeAnlage: this.versicherungssummeJeAnlage,
        versicherungssummeBUJeAnlage: this.versicherungssummeBUJeAnlage,
        SBMaschine: this.SBMaschine,
        SBBU: this.SBBU,
        haftzeitBU: this.haftzeitBU,
        nachlassFurVollwartungsvertrag: this.nachlassFurVollwartungsvertrag,
        MASCHINENKENNZIFFER: this.MASCHINENKENNZIFFER,
      };
      if (goNextPage) {
        this.$emit('basic-data-change', data, this.key);
      } else {
        this.$emit('basic-data-change', data, this.key, 1);
      }
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Windenergieanlagen-Grunddaten.pdf');
    },
    savePdf() {
      // save insurance, get id and make pdf
      this.$emit('add-to-queue', 'saveBasicPDF', '');
      this.onSubmit(false);
    },
  },
  computed: {
    vuexZurs(): number {
      return this.$store.getters['zuersZoneModule/getZurs'];
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);
    if (this.insuranceData && this.insuranceData.basicData) {
      this.setValues(this.insuranceData.basicData);
    }
    this.setZuersAddressToStore();
    (this as any).startWatcherActivated();
  },
});
